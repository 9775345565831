import React from 'react';
import firebase from '../firebaseConection'


export default function Todo({ todo }) {
  const deleteTodo = () => {
    const todoRef = firebase.database().ref('maisservico').child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('maisservico').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };
  return (
  
    <li>{todo.titulomaisservico}</li>
     
   
  );
}