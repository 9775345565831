import React from 'react';
import { Container } from 'semantic-ui-react'
import '../../App.css';
import './Contato.css'
//import BannerSection from '../BannerSection'
import ContactForSelect from '../form/Form'
//import { Button, Icon } from 'semantic-ui-react'
function Contato() {
  return (
    <>
      {/*<BannerSection bc="./images/cover/bc_contato.jpeg" altDescriao="Contatos Morind" />*/}
      <Container>
        <div className="contatoContainer_st">






          <div className="containerContactInterno">
            <div className="textoInterno">
              {/*  <p>Devido a experiência e conhecimento do mercado da indústria automobilística
              e a diversidade de projetos realizados e produtos representados, a Morind está
              pronta a realizar e avaliar diferentes projetos no setor industrial.
              A Morind conta com uma equipe de profissionais altamente qualificada que
              estará pronta a analisar e verificar a possibilidade de realização de novos
              projetos em conjunto.
              </p>*/}
              <h2 className="tituloContato_st">Horário de Funcionamento</h2>

              <p> Minas Gerais:    segunda a sexta
              08:00 – 18:00</p>

              <h2 className="tituloContato_st">Endereço</h2>

              <a href="https://goo.gl/maps/13uVjzzmzFmQXM9A8"   > <h4>Minas Gerais</h4>
                <div>
                  <p><i class="fas fa-map-marker-alt"> </i> R. Neide A. Araújo, 230 - Bandeirinhas, Betim - MG, 32657-190</p>
                  <button className="buttoncomoChegar"><i class="fas fa-location-arrow"></i><span> Como Chegar</span></button>
                </div>
              </a>

              <br /><br />

              

            </div>

            <div className="containerForm">
              <h1 className="tituloContato_st">Entre em contato conosco</h1>
              <p>Use o formulário abaixo para solicitar informações.</p>



              <ContactForSelect />
              <br />
             
            </div>

          </div>

        </div>
      </Container>
    </>
  );
}

export default Contato;