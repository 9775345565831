import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TopContact from './components/TopContact'
import LabelBottomNavigation from './components/NavInferior'
import Header from './components/Header'
import Home from './components/pages/Home'
import Serviços from './components/pages/Servicos'
import Galeria from './components/pages/Galeria'
import Equipe from './components/pages/Equipe'
import Contato from './components/pages/Contato'
import SobreNos from './components/pages/SobreNos'
import Footer from './components/Footer'


import './App.css';
import { Container } from 'semantic-ui-react'

//import ConectFirebase from './FirebaseConect'

//float button
import firebase from './firebase'


class App extends Component {

  state = {
    firebaseInitialized: false
}

  
componentDidMount(){
  firebase.isInitialized().then(resultado => {
    // Devolve o usuario
    this.setState({firebaseInitialized: resultado});
  })
}
 
 
 
 

  render() {
    return this.state.firebaseInitialized !== false ?(
      <BrowserRouter>
        <TopContact />
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/Servicos" exact component={Serviços} />
          <Route path="/Galeria" exact component={Galeria} />
          <Route path="/Servicos" exact component={Serviços} />
          <Route path="/Equipe" exact component={Equipe} />
          <Route path="/SobreNos" exact component={SobreNos} />
          <Route path="/Contato" exact component={Contato} />
          
          
         

        </Switch>
        <Footer />

        <div className="LabelBottomNavigationcContainer">
          <div className="LabelBottomNavigation">
            <LabelBottomNavigation />
          </div>
          <div className="copy1">
            <Container>
              <div className="copy">
                <div>
                  <p><img src="./images/logosvg.svg" alt="Morind Minas" /></p>
                </div>
              </div>
            </Container>
          </div>
        </div>





      </BrowserRouter>
    ) : (
        <h1> </h1>
    )


  }
}

export default App;




