import React from 'react';
import firebase from '../firebaseConection'

import ParceirosCards from '../ParceirosCards'



export default function Todo({ todo }) {
  const deleteTodo = () => {
    const todoRef = firebase.database().ref('parceiro').child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('parceiro').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };
  return (
    <div>
      <ParceirosCards parceiroImage={todo.parceiroImage} alt={todo.parceiroNome}/>
     
    </div>
  );
}