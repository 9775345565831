import React from 'react';
import { Container } from 'semantic-ui-react'
import BannerCompany from '../BannerCompany'
import '../../App.css';
import './Servicos.css'
import Parceiros from '../Parceiros'

//import Banner from '../Banner'
import ServicesItems from '../ServicesItems'
import  TodoList from '../returnmaisservico/TodoList'
function Serviços() {
  return (
    <>

      <BannerCompany />
      <Container>
        <div className="serviceSpace">
          <ServicesItems />

        </div>

        <div className="maisServicos_st">
          <h1 className="tituloMaisServicos_st">Mais serviços</h1>
          <div className="conteudoMaisServicos_st">
            <div className="listMaisServicos_st">
              <ul>
                
                <TodoList />

              </ul>
            </div>
          </div>
        </div>
        <div className="bcCor3">
          <Parceiros />
        </div>
      </Container>
    </>
  );
}

export default Serviços;