import React, { Component } from 'react'
import ContactUsSudeste from './mailsudeste'
import ContactUsNordeste from './mailnordeste'
import Vendas from './mailvendas'
import './MailForm.css'





class ContactForSelect extends Component {

  constructor(props) {
    super(props);
    //neste estado
    this.state = {
      location: '',
      vendasSelect: '',
    };
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.onChangeVendas = this.onChangeVendas.bind(this)
  }
  onChangeLocation(e) {
    let valorLocation = e.target.value;
    this.setState({ location: valorLocation })
    this.setState({ vendasSelect: '' })
  }

  onChangeVendas(e) {
    let selecao = e.target.value;
    this.setState({ vendasSelect: selecao })
    this.setState({ location: '' })
  }



  render() {
    return (


      <div>
        <div className="options">


          <div>
            <h1 className="h1Form_st">Qual o serviço deseja atendimento?</h1>
            <div className="Fiels">
              <div className="label_st">
                <select name="vendasSelect" onChange={this.onChangeVendas} placeholder="Selecione um serviço">
                  <option>Selecione um serviço</option>
                  <option value="vendas">Instalações industriais</option>
                  <option value="vendas">Sistema de combate e detecção de incêndio</option>
                  <option value="vendas">Tubulações</option>

                  {/*usar select caso queira colocar as opções de nordeste ex  <option value="select">Tubulações</option>*/}

                  <option value="vendas">Elementos de ultra filtração</option>
                  <option value="vendas">Células de diálise</option>
                  <option value="vendas">Medições de tensão em pintura cataforética"</option>
                  <option value="vendas">Avaliação e medições em cabines</option>
                  <option value="vendas">Fornos e sistemas periféricos</option>
                  <option value="vendas">IRT</option>
                  <option value="vendas">UFS</option>
                </select>
              </div>
            </div>
          </div>
         


        </div>



        {
          this.state.vendasSelect === "vendas" &&
          <Vendas />
        }

        {this.state.location === "Norte-Nordeste" &&
          <ContactUsNordeste />

        }

        {this.state.location === "Sul-Sudeste" &&
          <ContactUsSudeste />

        }








        <div>


        </div>
      </div>
    )
  }
}





export default ContactForSelect

















