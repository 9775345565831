import React from 'react'
import { Container } from 'semantic-ui-react'
import Contacts from './Contacts'
import SocialMedia from './SocialMedia'
import './Footer.css'
function Footer() {
  return (
    <div className="Footer_st">
      <Container>
        <div className="SocialAndContacts_st">
          <Contacts />
          <SocialMedia />
        </div>
      </Container>
    </div>
  )
}

export default Footer
