import React from 'react'
import ClientesCards from './ClientesCards'
import './Clientes.css'
import  PrincipalCliente from './returnclientes/Principal'
import TodoListCliente from './returnclientes/TodoList'

function Clientes() {
  return (
    <>
      <h1 className="tituloClientes_st">Nossos Clientes</h1>
    
      <TodoListCliente />



    
    </>
  )
}

export default Clientes
