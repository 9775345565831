import React from 'react'
import './MembroEquipeCard.css'

function MembroEquipeCard(props) {
  return (
    <div className="membroEquipeCard_st">


      <div className="dataMembroEquipeCardContainer_st">
        <div className="imgMembroEquipeCard_st">
          <img src={props.imgMembro} alt={props.altMembro} />
        </div>
        <div className="dataMembroEquipeCard_st">
          <p className="membroNome_st">{props.membroNome}</p>
          <p className="membroCargo_st">{props.membroCargo}</p>
        </div>
      </div>

    </div>
  )
}

export default MembroEquipeCard
