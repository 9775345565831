import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Call from '@material-ui/icons/Call'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import '../App.css'
import { Mail } from '@material-ui/icons';
import { Button, Grid, Popup } from 'semantic-ui-react'

/////////////////////////////
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ContactForSelect from './form/Form'
import './form/Modal'
import './form/MailForm.css'
/////////////////////////////////////


const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    background: '#0099FE',
    color: 'white',
    fontFamily: 'Abel',
    label: 'white',


  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),



  },



}));
/////////////////////////////////////////
function TransitionsModal() {

 
 




  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>







      <Mail style={{ color: 'white' }} onClick={handleOpen} />






      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>


            <ContactForSelect />

          </div>
        </Fade>
      </Modal>
    </div>
  );
}




const PopupExampleNested = () => (
  <Popup wide trigger={<Call style={{ color: 'white' }} />} on='click'>
    <Grid divided columns='equal'>
      <Grid.Column>
        <Popup
          trigger={<a href="tel:+55-31-3532-4957"><Button color='red' content='Sudeste' fluid /></a>}
          content='Contatar atendimento Sul/Sudeste'

          position='top center'
          size='tiny'

        />
      </Grid.Column>
      
    </Grid>
  </Popup>
)








////////////////////////////////////
export default function LabelBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} className={classes.root}>

      <a href="http://www.morind.com.br/" target="_self" rel="noopener noreferrer"> <BottomNavigationAction
        label={<span style={{ color: 'white' }} >Portfolio de Serviços</span>}
        value="Portfolio de Serviços"
        icon={<PhotoLibraryIcon style={{ color: 'white', }} />} />
      </a>
      <BottomNavigationAction
        label={<span style={{ color: 'white' }} >Ligar</span>}
        value="Ligar"
        icon={<PopupExampleNested />} />


      <BottomNavigationAction
        label={<span style={{ color: 'white' }} >Cotações</span>}
        value="Cotações"
        icon={<TransitionsModal />} />





    </BottomNavigation>
  );
}
