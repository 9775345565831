import React from 'react';
import { Container } from 'semantic-ui-react'
import '../../App.css';
import './Equipe.css'
import { Button, Icon } from 'semantic-ui-react'

//import Banner from '../Banner'
import MembroEquipe from '../MembroEquipe'
import Iframe from 'react-iframe'



function Equipe() {
  return (
    <>



      <div className="bcfcmes">

        <Container>
          <div className="equipeContainer_st">

            <div className="funcionariosdomes">
              {/*  <h1 className="tituloEquipe_st">FUNCIONÁRIOS DO MÊS</h1>*/}
              <div className="containerFuncionario">
                <div className="textofuncionario2">
                  <h1>Nosso time é nosso maior valor!</h1>
                  <p>Através disso procuramos identificar os funcionários que mais se destacam em suas atividades, tornando-o assim,funcionário do mês.</p>
                </div>

                <Iframe url="../FuncionarioMes.html"
                  width="100%"
                  id="FuncionarioMes"
                  className="FuncionarioMes"
                  display="initial"
                  position="relative"
                  scrolling="no"
                  frameBorder="0"
                  title="FuncionarioMes" />
              </div>
            </div>
          </div>

        </Container>


      </div>



      <Container>
        <div className="aniversariantes">
          <h1 className="tituloEquipe_st">Aniversariantes do Mês</h1>
          <div className="textofuncionario">
            <p>Veja quem do nosso time está de idade nova e não deixe de dar os parabéns a esses profissionais dedicados, que a cada dia se superam dando o seu melhor.
              </p>
          </div>
          <Iframe url="../Aniversariantes.html"
            width="100%"
            id="Aniversariantes"
            className="Aniversariantes"
            display="initial"
            position="relative"
            scrolling="no"
            frameBorder="0"
            title="Aniversariantes" />


        </div>

        <div className="galeriaFuncionarios">
          <a href="https://www.instagram.com/morindgroup/" alt="Galeria de Serviços Industriais"> <Button color='instagram'>
            <Icon name='instagram' /> Confira todos os aniversariantes no Instagram
          </Button>
          </a>
        </div>

      </Container>






      <Container>
        <MembroEquipe />
      </Container>
    </>
  );
}

export default Equipe;



