import React from 'react'
import './MembroEquipe.css'
import MembroEquipeCard from './MembroEquipeCard'

function MembroEquipe() {
  return (
    <>
      <h1 className="tituloMembros_st" >Conheça Nossos Especialistas</h1>
      <div className="membros_st">


        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Juan Alloza Morillo"
          membroCargo="Diretor de Operações"
        />


        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Juan Carlos Marion Alloza"
          membroCargo="Engenharia de Aplicação"
        />

        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Givanildo Menezes"
          membroCargo="Gerente de Operações"

        />



        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Marcos Silva "
          membroCargo="Coordenação de Projetos"
        />



        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Antonio Padilha"
          membroCargo="Medições e regulagens em fornos/cabines de pintura"

        />


        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Luigi da C. Longo"
          membroCargo="Engenharia e Vendas"

        />


        <MembroEquipeCard
          imgMembro="./images/reviews/46.png"
          altMembro=""
          membroNome="Rafael Ferreira Sen"

          membroCargo="Engenharia e Vendas"

        />






      </div>
    </>
  )
}

export default MembroEquipe
