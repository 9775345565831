import React, {Component} from 'react'
import { Container } from 'semantic-ui-react'

import './BannerSobreNos.css'
import firebase from './dashboard/firebaseConection'




class BannerSobreNos extends Component{
  

  constructor (props){
    super(props);
    this.state ={
      descricaomissao: '',
      descricaovisao: '',
      descricaovalores: '',
      
    }
  
  
  
    firebase.database().ref('institucional').on('value', (snapshot)=>{
   let state = this.state;
   state.descricaomissao = snapshot.val().descricaomissao;
   state.descricaovisao = snapshot.val().descricaovisao;
   state.descricaovalores = snapshot.val().descricaovalores;
   
   this.setState(state);
 });
   
  }



  
render(){
  return (
    <>
      <div className="BannerSobreNos_st">
        <div className="bcBannerSobreNos_st">
          <Container>
            <div className="contentContainer_st">






              <div className="content_st">

                <div className="icon_st"></div>
                <div className="politicas">
                  <div><h1 className="tituloPoliticas_st">MISSÃO</h1></div>
                  <div className="contentPoliticas">
                    <p>
                    {this.state.descricaomissao}
                     
                    </p>
                  </div>
                </div>


              </div>


              <div className="content_st">

                <div className="icon_st"></div>
                <div className="politicas">
                  <div ><h1 className="tituloPoliticas_st">VISÃO</h1></div>
                  <div className="contentPoliticas">
                    <p>

                     {this.state.descricaovisao}
                      


                    </p>
                  </div>
                </div>





              </div>


              <div className="content_st">
                <div className="icon_st"></div>
                <div className="politicas_st">
                  <div ><h1 className="tituloPoliticas_st">VALORES</h1></div>
                  <div className="contentPoliticas_st">
                    <p>

                    {this.state.descricaovalores}


                    </p>
                  </div>
                </div>
              </div>



            </div>
          </Container>
        </div>


      </div>
    </>
  )
}
}

export default BannerSobreNos
