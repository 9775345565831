import firebase from 'firebase/app'
import 'firebase/database'




let firebaseConfig = {
  apiKey: "AIzaSyAyVXs8gQbF_rWJIn_oX6d0tVXllSmq2rQ",
  authDomain: "morindminas-301d3.firebaseapp.com",
  databaseURL: "https://morindminas-301d3.firebaseio.com",
  projectId: "morindminas-301d3",
  storageBucket: "morindminas-301d3.appspot.com",
  messagingSenderId: "569659748171",
  appId: "1:569659748171:web:cd413c1bacaec2459982ce",
  measurementId: "G-D9K3MSSVR6"
};
// Initialize Firebase
if (!firebase.apps.length) { firebase.initializeApp
(firebaseConfig)}
 
  

export default firebase