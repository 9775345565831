import React from 'react'
import { List } from 'semantic-ui-react'
import './SocialMedia.css'

function SocialMedia() {
  return (
    <div>
      <h1 className="tituloSocialMedia_st">Siga-nos nas redes sociais</h1>
      <List className="textoListaCor_st">
        
        <List.Item>
          <List.Icon name='mail' />
          <List.Content className="white">
            <a href='mailto:mail@morind.com.br'>mail@morind.com.br</a>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='linkify' />
          <List.Content className="white">
            <a href='https://www.morind.com.br/'>Morind</a>
          </List.Content>
        </List.Item>
      </List>
    </div>
  )
}

export default SocialMedia
