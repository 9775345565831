import React from 'react'

import './BannerCompany.css'

function BannerCompany() {
  return (
    <>
      <div className="BannerCompany_st">
        <div className="bcBannerCompany_st">

          <div className="introBannerCompany_st">
            <h1>PALAVRAS QUE DEFINEM NOSSOS SERVIÇOS <br /></h1>
            <h3> QUALIDADE - EXPERIÊNCIA - DEDICAÇÃO - INOVAÇÃO</h3>

          </div>
        </div>


      </div>
    </>
  )
}

export default BannerCompany
