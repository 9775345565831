import React,{Component}  from 'react'
import './Portfolio.css'
import firebase from './dashboard/firebaseConection'




class Portfolio extends Component {
  
  constructor (props){
    super(props);
    this.state ={
      descricao: '',
      link: '',
      
    }
  
  
  
    firebase.database().ref('portfolio').on('value', (snapshot)=>{
   let state = this.state;
   state.descricao = snapshot.val().descricao;
   state.link = snapshot.val().link;
   
   this.setState(state);
 });
   
  }



render(){

  return (
    <div>
       {/*<h1 className="tituloPortfolio_st">Portfólio de Serviços</h1>*/}
      <div className="contentPortfolio_st">
        {/* <p>
         {this.state.descricao}
        </p>*/}
  {/*<a href={this.state.link}><img src="./images/img-9.png" alt="Portfólio de Serviços Industriais Morind" /></a>*/}
        <a href="https://morind.com.br"><img src="./images/img-9.png" alt="Portfólio de Serviços Industriais Morind" /></a>
      </div>
    </div>
  )
}
}
export default Portfolio
