import React from 'react';
import firebase from '../dashboard/firebaseConection'
import ClientesCards from '../ClientesCards'
import '../Clientes.css'
export default function Todo({ todo }) {

  
  const deleteTodo = () => {
    const todoRef = firebase.database().ref('cliente').child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('cliente').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };




  return (
    <div >
     
    <ClientesCards clienteImage={todo.clienteImage} alt={todo.clienteNome} />
   
    </div>
  );
}