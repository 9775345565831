import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './Banner.css'
import firebase from './dashboard/firebaseConection'



class Banner extends Component {

  constructor (props){
    super(props);
    this.state ={
      bannerintro: '',
    }
  
  
  
    firebase.database().ref('bannerintro').once('value').then((snapshot) => {
      let state = this.state;
      state.bannerintro = snapshot.val();
      this.setState(state);
    });
  
  
  }




render(){
  return (
    <>
      <div className="Banner_st">
        <div className="bcBanner_st">
          <video src='/videos/video-1.mp4' autoPlay loop muted />
          <div className="intro">
            <h1>{this.state.bannerintro}</h1>
            <p><Link to='/Servicos'><Button color='blue'>CONHEÇA NOSSOS SERVIÇOS</Button></Link></p>
          </div>
        </div>








      </div>
    </>
  )
  }
}

export default Banner
