import React, { Component } from 'react'
import emailjs from 'emailjs-com';
import './MailForm.css'

//Mail funcion
function sendEmail(e) {

  e.preventDefault();
  emailjs.sendForm('gmail', 'template_8rr6eea', e.target, 'user_JxqP7BpulCmNeiayqBTEu')
    .then((result) => {
      console.log(result.text);
      alert('Mensagem Enviada com Sucesso')
    }, (error) => {
      console.log(error.text);
    });
  e.target.reset()
}



class ContactUsSudeste extends Component {


  render(props) {
    return (
      <form className="contact-form" onSubmit={sendEmail}>
        <h1 className="h1FormAtendimento_st">Atendimento Sul/Sudeste</h1>
        <div className="containerFiels">
          <input type="hidden" name="contact_number" />
          <div className="Fiels">
            <div className="label_st">
              <label>Nome</label>
            </div>
            <div className="label_st">
              <input type="text" name="name" placeholder="Seu nome..." required />
            </div>
          </div>
          <div className="Fiels">
            <div className="label_st">
              <label>Email</label>
            </div>
            <div className="label_st">
              <input type="email" name="email" placeholder="Seu email..." required />
            </div>
          </div>
          {/* 
          <div className="Fiels">
            <div className="label_st">
              <label>Assunto</label>
            </div>
            <div className="label_st">
              <input type="text" name="subject" placeholder="Assunto ou título da mensagem"  />
            </div>
          </div>
          */}
        </div>
        <div className="Fiels">
          <div className="label_st">
            <label>Mensagem</label>
          </div>
          <div className="label_st">
            <textarea name="catag" placeholder="Gostaria de saber os valores para o serviço..." required />
          </div>
        </div>
        <div className="label_Button_st">
          <input type="submit" value="Enviar" />
        </div>
      </form>
    );
  }
}




















export default ContactUsSudeste










































