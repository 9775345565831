import React from 'react'
import './ClientesCards.css'

function ClientesCards(props) {
  return (
    <div className="ClientesCards_st">
      <img src={props.clienteImage} alt={props.alt} />
    </div>
  )
}

export default ClientesCards
