import React from 'react';

import TodoListServicoCard from './TodoList';


export default function PrincipalServicoCard() {
  return (
    <div className="App">
    
      <TodoListServicoCard />
      
    </div>
  );
}