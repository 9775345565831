import React from 'react'
import About from './About'
import Portfolio from './Portfolio'
import './AboutAndPortfolio.css'

function AboutAndPortfolio() {
  return (
    <div className="AboutAndPortfolio_st">
      <About />
      <Portfolio />
    </div>
  )
}

export default AboutAndPortfolio
