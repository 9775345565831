import React from 'react';

import TodoListServico from './TodoList';


export default function PrincipalServicoCon2() {
  return (
    <div className="App">
  
  
      <TodoListServico />
      
    </div>
  );
}