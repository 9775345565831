import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import Iframe from 'react-iframe'
import './Gallery.css'

function Gallery() {
  return (
    <div className="Gallery_st">





      <Iframe url="../Gallery.html"
        width="100%"


        id="GaleriaServicos"
        className="GaleriaServicos"
        display="initial"
        position="relative"
        scrolling="no"
        frameBorder="0"
        title="GaleriaServicos"

      />

      <div className="galeriaCompleta">
        <a href="https://www.instagram.com/morindgroup/" alt="Galeria de Serviços Industriais"> <Button color='instagram'>
          <Icon name='instagram' /> Confira a galeria completa no Instagram
        </Button>
        </a>
      </div>

    </div>
  )
}

export default Gallery
