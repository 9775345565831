import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
//import Logo from './images/logo.png'
import Button from './Button'
import './Header.css'


import TransitionsModal from './form/Modal'



export default function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)
  const [button, setButton] = useState(true);



  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);




  window.addEventListener('resize', showButton);



  return (

    <>
      <nav className='navbar'>

        <div className='navbar-container'>

          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>

            <svg width="152" height="42" viewBox="0 0 687 188" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.9863 49.8795H87.0233V63.3592C91.6321 57.7354 96.2691 53.735 100.934 51.3579C105.655 48.9809 111.332 47.7924 117.964 47.7924C125.102 47.7924 130.751 49.0968 134.91 51.7058C139.069 54.3148 142.47 58.1992 145.111 63.3592C150.507 57.3296 155.425 53.2422 159.865 51.097C164.305 48.8939 169.785 47.7924 176.305 47.7924C185.916 47.7924 193.42 50.7492 198.815 56.6628C204.211 62.5185 206.909 71.7079 206.909 84.2309V142.237H172.511V89.6227C172.511 85.4484 171.725 82.3466 170.151 80.3174C167.846 77.1287 164.98 75.5343 161.551 75.5343C157.505 75.5343 154.245 77.0417 151.772 80.0565C149.299 83.0713 148.062 87.9124 148.062 94.5798V142.237H113.665V91.3621C113.665 87.3037 113.44 84.5498 112.99 83.1003C112.259 80.7813 110.995 78.926 109.196 77.5345C107.398 76.0851 105.29 75.3604 102.873 75.3604C98.9388 75.3604 95.707 76.8968 93.1778 79.9696C90.6485 83.0424 89.3839 88.0863 89.3839 95.1016V142.237H54.9863V49.8795Z" fill="#0099FE" />
              <path d="M223.18 96.3191C223.18 82.2307 227.789 70.6353 237.007 61.5329C246.225 52.3725 258.674 47.7924 274.355 47.7924C292.285 47.7924 305.83 53.1552 314.992 63.881C322.354 72.5195 326.036 83.1583 326.036 95.7973C326.036 110.002 321.455 121.655 312.294 130.757C303.189 139.802 290.57 144.324 274.44 144.324C260.051 144.324 248.417 140.556 239.536 133.019C228.632 123.684 223.18 111.451 223.18 96.3191ZM257.578 96.2321C257.578 104.465 259.18 110.552 262.384 114.495C265.643 118.437 269.718 120.409 274.608 120.409C279.554 120.409 283.601 118.466 286.748 114.582C289.952 110.697 291.554 104.465 291.554 95.8843C291.554 87.8834 289.952 81.9408 286.748 78.0563C283.545 74.1139 279.582 72.1427 274.861 72.1427C269.859 72.1427 265.728 74.1429 262.468 78.1433C259.208 82.0857 257.578 88.1153 257.578 96.2321Z" fill="#0099FE" />
              <path d="M342.897 49.8795H375.019V65.0115C378.11 58.4601 381.286 53.9669 384.546 51.5319C387.862 49.0389 391.937 47.7924 396.77 47.7924C401.829 47.7924 407.365 49.4157 413.379 52.6624L402.756 77.8824C398.709 76.1431 395.506 75.2734 393.145 75.2734C388.649 75.2734 385.164 77.1867 382.691 81.0132C379.15 86.405 377.379 96.493 377.379 111.277V142.237H342.897V49.8795Z" fill="#0099FE" />
              <path d="M420.545 14.7455H454.858V38.8349H420.545V14.7455ZM420.545 49.8795H454.858V142.237H420.545V49.8795Z" fill="#0099FE" />
              <path d="M476.778 49.8795H508.731V64.9246C513.508 58.779 518.342 54.4017 523.232 51.7928C528.122 49.1258 534.079 47.7924 541.105 47.7924C550.604 47.7924 558.023 50.7202 563.362 56.5759C568.758 62.3736 571.456 71.36 571.456 83.5352V142.237H536.974V91.449C536.974 85.6513 535.934 81.5639 533.855 79.1869C531.775 76.7519 528.852 75.5343 525.087 75.5343C520.927 75.5343 517.555 77.1577 514.97 80.4044C512.384 83.6511 511.092 89.4778 511.092 97.8845V142.237H476.778V49.8795Z" fill="#0099FE" />
              <path d="M686.199 14.7455V142.237H654.078V128.583C649.581 134.381 645.478 138.294 641.769 140.324C636.823 142.991 631.315 144.324 625.244 144.324C613.048 144.324 603.718 139.541 597.254 129.975C590.847 120.409 587.643 108.784 587.643 95.1016C587.643 79.7956 591.184 68.0843 598.266 59.9675C605.404 51.8507 614.453 47.7924 625.413 47.7924C630.752 47.7924 635.586 48.72 639.914 50.5753C644.298 52.4305 648.176 55.2134 651.548 58.9239V14.7455H686.199ZM651.801 95.7973C651.801 88.5502 650.312 83.1583 647.333 79.6217C644.354 76.0271 640.588 74.2299 636.036 74.2299C632.045 74.2299 628.701 75.9402 626.003 79.3608C623.305 82.7815 621.956 88.5212 621.956 96.58C621.956 104.117 623.333 109.654 626.087 113.19C628.898 116.669 632.354 118.408 636.457 118.408C640.841 118.408 644.495 116.64 647.417 113.103C650.34 109.567 651.801 103.798 651.801 95.7973Z" fill="#0099FE" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0771 163.164V24.8361H401.776V4.84816H4.7V183.152H681.153V163.164H24.0771ZM0 188V0H406.476V29.6842H28.7771V158.316H685.853V188L0 188Z" fill="#6F6F6F" />
            </svg>


          </Link>

          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>





          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'><Link to='/' className='nav-links' onClick={closeMobileMenu}>Home</Link></li>
            <li className='nav-item'><Link to='/Servicos' className='nav-links' onClick={closeMobileMenu} >Serviços</Link></li>
            {/* <li className='nav-item'><Link to='/Galeria' className='nav-links' onClick={closeMobileMenu}>Galeria</Link></li>*/}
            {/*  <li className='nav-item'><Link to='/Equipe' className='nav-links' onClick={closeMobileMenu}>Equipe</Link></li>*/}
            <li className='nav-item'><Link to='/SobreNos' className='nav-links' onClick={closeMobileMenu}>Sobre Nós</Link></li>
            <li className='nav-item'><Link to='/Contato' className='nav-links' onClick={closeMobileMenu}>Contato</Link></li>

            <li className='nav-links'><TransitionsModal /></li>


          </ul>



          {button && <Button buttonStyle='btn--outline'>    </Button>}



        </div>

      </nav>
    </>

  )
}