import React from 'react';
import firebase from './firebaseConection'
import ServicesItemCard from '../ServicesItemCard'

export default function Todo({ todo }) {




  const deleteTodo = () => {
    const todoRef = firebase.database().ref('servico_con1').child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('servico_con1').child(todo.id);
    todoRef.update({
      servicoTituloPrincipal: !todo.servicoTituloPrincipal,
    });
  };
  return (
    <div>

      <ServicesItemCard

        tituloServico={todo.servicoTituloPrincipal}
        subtituloServico={todo.servicoSubTitulo}
        image={todo.servicoImgCover}
        alt={todo.servicoSubTitulo} icon={todo.servicoIco}
        content={
          <div>
            <h1>{todo.servicoTitulo1}</h1>
            <p>{todo.servicoP1}</p>
            <p>{todo.servicoP2}</p>
            <p>{todo.servicoP3}</p>
            <h1>{todo.servicoTitulo2}</h1>
            <p>{todo.servicoP4}</p>
            <p>{todo.servicoP5}</p>
            <p>{todo.servicoP6}</p>
            <h1>{todo.servicoTitulo3}</h1>
            <p>{todo.servicoP7}</p>
            <p>{todo.servicoP8}</p>
            <p>{todo.servicoP9}</p>


          </div>} />


    </div>
  );
}