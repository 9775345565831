import React from 'react';
import { Container } from 'semantic-ui-react'
import '../../App.css';
import './Galeria.css'

//import Feed from "react-instagram-authless-feed"
//import Banner from '../Banner'

import Gallery from '../Gallery'
function Galeria() {

  return (
    <>
      <Container>
        <div className="galeriaContainer_st">

          <h1 className="tituloGaleria_st">Últimos Serviços</h1>

          <div>

          </div>
          <Gallery />
        </div>
      </Container>
    </>
  );
}

export default Galeria;