import React,{Component} from 'react';
import { Container } from 'semantic-ui-react'
import BannerSobreNos from '../BannerSobreNos'
import '../../App.css';
import './SobreNos.css'
//import Banner from '../Banner'
import firebase from '../dashboard/firebaseConection'


class SobreNos extends Component {


  constructor (props){
    super(props);
    this.state ={
      descricaosobrenos: '',
      descricaopolitica: '',
      lista: [],
      
    }
  
  
  
   firebase.database().ref('institucional').on('value', (snapshot)=>{
   let state = this.state;
   state.descricaosobrenos = snapshot.val().descricaosobrenos;
   state.descricaopolitica = snapshot.val().descricaopolitica;
   
   this.setState(state);
 });
   
    
    
    
 firebase.database().ref('institucionalobjetivo').on('value', (snapshot) => {
  let state = this.state
  state.lista=[]


  //pegando tudo

  snapshot.forEach((childItem) => {
    state.lista.push({
      key:childItem.key,
      tituloobjetivo: childItem.val().tituloobjetivo,
      
    })
  })
  this.setState(state)
})
    
    
    
    
    

    
  }




  render(){
  return (
    <>
      <BannerSobreNos>

      </BannerSobreNos>
      <Container>
        <div className="sobrenosSpace_st">
          <h1 className="tituloSobrenos_st">Sobre Nós</h1>


<p> 
{this.state.descricaosobrenos}


</p>

          
          
          
          </div>
        
        
        
        
        
        <div className="sobrenosSpace_st">
          <h1 className="tituloSobrenos_st">Política</h1>
          <p>
         {this.state.descricaopolitica}
          </p>
        </div>
        <div className="sobrenosSpace_st">
          <h1 className="tituloSobrenos_st">Objetivos</h1>
          <ul className="ul_st">


          {this.state.lista.map((item) => {
            return(
             
              <li>{item.tituloobjetivo}</li>
              
              
            )
          })}
          




          
          

          </ul>
        </div>
      </Container>
    </>
  );
}
}
export default SobreNos;