import React,{Component} from 'react'
import { List } from 'semantic-ui-react'
import './Contacts.css'
import firebase from './dashboard/firebaseConection'



class Contacts extends Component {

  constructor (props){
    super(props);
    this.state ={
      minasgerais: '',
      pernambuco: '',
      saopaulo: '',
    }
  
  
  
    firebase.database().ref('telefones').on('value', (snapshot)=>{
   let state = this.state;
   state.minasgerais = snapshot.val().minasgerais;
   state.pernambuco = snapshot.val().pernambuco;
   state.saopaulo = snapshot.val().saopaulo;
   this.setState(state);
 });
   
  }



  render(){
  return (
    <div>
      <h1 className="tituloContacts_st">Endereços e Contatos</h1>
      <List className="textoListaCor_st">

        <List.Item>
          <List.Content>Região Sudeste</List.Content>
          <List.Content>__________________________________</List.Content>
        </List.Item>

        <List.Item>
          <List.Content>Minas Gerais</List.Content>
        </List.Item>

        <List.Item>
          <List.Icon inverted color='grey' name='phone' />
          <List.Content className="white"><a href={`tel:+55-${this.state.minasgerais}`}>{this.state.minasgerais}</a></List.Content>
          <List.Content><br /></List.Content>
        </List.Item>

      

        <List.Item>
          <List.Content>Endereço</List.Content>
          <List.Content>__________________________________</List.Content>
        </List.Item>

        <List.Item>
          <List.Content><br /></List.Content>
          <List.Content>R. Neide A. Araújo, 230 - Bandeirinhas, Betim - MG, 32657-190</List.Content>
        </List.Item>

       

      </List>
    </div>
  )
}

}
export default Contacts
