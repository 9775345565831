import React from 'react';
import { Container } from 'semantic-ui-react'
import '../../App.css';
import './Home.css'

import Banner from '../Banner'
import ServicesItems from '../ServicesItems'
import AboutAndPortfolio from '../AboutAndPortfolio'
import Clientes from '../Clientes'
//import Reviews from '../Reviews'
import Parceiros from '../Parceiros'
//import Form from '../Form'






function Home() {
  //const src = '../images/logo.png'
  return (
    <>
      <Banner />
      <div className="bcCor">
        <div className="bcCor1">
          <Container>
            <ServicesItems />
          </Container>
        </div>
        <div className="bcCor3">
          <Container>
            <AboutAndPortfolio />
          </Container>
        </div>

        <div className="bcCor4">
          <Container>
            <Clientes />
          </Container>
        </div>
        {/**  <div className="bcCor5">
          <Container>
            <Reviews />
          </Container>





           <div className="bcCor3">
            <Container>
              <Form />

            </Container>
          </div>
        </div>*/}

        <div className="bcCor5">
          <Container>
            <Parceiros />
          </Container>
        </div>




      </div>




    </>
  );
}

export default Home;