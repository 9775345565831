import React, { Component } from 'react'
import './About.css'
import firebase from './dashboard/firebaseConection'



class About extends Component {
constructor (props){
  super(props);
  this.state ={
    sobrenos: '',
  }



  firebase.database().ref('sobrenos').once('value').then((snapshot) => {
    let state = this.state;
    state.sobrenos = snapshot.val();
    this.setState(state);
  });


}



  render(){
  return (

    <div>
      <h1 className="tituloAbout_st">Sobre Nós</h1>
      <div className="contentAbout_st">
        {this.state.sobrenos}
       
      </div>

    </div>
  )
}
}
export default About
