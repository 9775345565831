import React from 'react'
import ParceirosCards from './ParceirosCards'
import './Parceiros.css'
import TodoListParceiro from './returnparceiros/TodoList'


function Parceiros() {
  return (
    <>
      <h1 className="tituloParceiros_st">Nossos Parceiros</h1>
      <p>Trabalhando em representação e parceria com empresas fornecedoras de tecnologia, sistemas ou equipamentos em sua área de atuação, buscamos abranger a necessidade do cliente em todos os seus aspectos.</p>
      
<TodoListParceiro />
       
       
       
       
       
       
       
       
       
       



      
    </>
  )
}

export default Parceiros
