import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';




let firebaseConfig = {
  apiKey: "AIzaSyAyVXs8gQbF_rWJIn_oX6d0tVXllSmq2rQ",
  authDomain: "morindminas-301d3.firebaseapp.com",
  databaseURL: "https://morindminas-301d3.firebaseio.com",
  projectId: "morindminas-301d3",
  storageBucket: "morindminas-301d3.appspot.com",
  messagingSenderId: "569659748171",
  appId: "1:569659748171:web:cd413c1bacaec2459982ce",
  measurementId: "G-D9K3MSSVR6"
};
// Initialize Firebase



class Firebase { 

  constructor() {
    if (!app.apps.length) { app.initializeApp
      (firebaseConfig)
    }

    this.app = app.database();
    app.auth()
}
login(email, password){
  return app.auth().signInWithEmailAndPassword(email, password)
}



 async register(nome,email, password) {
   await app.auth().createUserWithEmailAndPassword(email, password)
   
   const uid = app.auth().currentUser.uid;

   return app.database().ref('usuarios').child(uid).set({
    nome: nome
  })

} 

isInitialized(){
  return new Promise(resolve =>{
      app.auth().onAuthStateChanged(resolve);
  })
}


  getCurrent(){
    return app.auth().currentUser && app.auth().currentUser.email
  }



  
  

  

  
}

export default new Firebase()