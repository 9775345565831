import React, { Component } from 'react'
import ServicesItemCard from './ServicesItemCard'
import './ServicesItems.css'

//import Incendio from './images/svg/1.svg'


import PrincipalServicoCard from './returnservico/Principal'
import PrincipalServicoCon2 from './servicoscol2/Principal'
import PrincipalServicoCon3 from './servicoscol3/Principal'
class ServicesItems extends Component {

  render(){
  return (
    <>
      <h1 className="titleServicesItems_st">Áreas de Atuação
       </h1>
      <div className="ServicesItems_st">

        <div className="box1_st">
          
          <PrincipalServicoCard />

        </div>
        
        <div className="box2_st">
        <PrincipalServicoCon2 />
        </div>



        <div className="box3_st">
          <PrincipalServicoCon3 />

        </div>


      </div>
    </>
  )
}
}
export default ServicesItems
