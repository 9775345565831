import React from 'react'
import './ParceirosCards.css'

function ParceirosCards(props) {
  return (
    <div className="ParceirosCards_st">
      <img src={props.parceiroImage} alt={props.alt} />
    </div>
  )
}

export default ParceirosCards
