import React from 'react';

import TodoListServico from './TodoList';


export default function PrincipalServicoCon3() {
  return (
    <div className="App">
      <TodoListServico />
      
    </div>
  );
}